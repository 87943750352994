<template>
  <div
    class="managingPeople hp100 bbox"
    v-loading="loading"
    element-loading-text="正在导出,请耐心等待"
    element-loading-spinner="el-icon-loading"
  >
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索-->
      <el-row
        :gutter="20"
        type="flex"
        class="toRight"
        style="margin-bottom: 5px"
      >
        <!-- <el-col :span="4" >
                      <el-input
                                placeholder="请输入数量"
                                clearable
                                v-model="searchInput"
                                class="header-search-input ml10 toRight"
                                style="margin-left:0px;width:200px;"
                        > </el-input>
                        
                </el-col> -->
        <el-col>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            @change="getDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="1.5">
          <el-button
            plain
            class="add-column-btn bbox toRight"
            @click="search"
            type="primary"
            style="margin-left: 0px"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="1.5">
          <el-button
            plain
            class="add-column-btn bbox toRight"
            @click="exportCode"
            type="primary"
            style="margin-left: 0px"
          >
            批量导出</el-button
          >
        </el-col>
        <el-col :span="1.5">
          <el-button
            plain
            class="add-column-btn bbox toRight"
            @click="open"
            type="primary"
            style="margin-left: 0px"
          >
            批量生成</el-button
          >
        </el-col>
        <el-col :span="1.5">
          <!-- <el-button plain class="add-column-btn bbox toRight" @click="gotoDetail('')" type="primary"> <i class="el-icon-plus fw900 f16"></i> 导出至EXCEL</el-button> -->
          <el-button
            plain
            class="add-column-btn bbox toRight"
            @click="gotoDetail('')"
            type="primary"
          >
            新建</el-button
          >
        </el-col>
        <!-- <el-col>
                    <div class="flex align-center justify-end header-search-box">
                        <el-input
                                placeholder="请输入激活码"
                                clearable
                                v-model="searchInput"
                                class="header-search-input ml10"
                        >
                            <el-button
                                    slot="append"
                                    icon="el-icon-search"
                                    class="textblue searchBtn"
                                    @click="search()"
                            ></el-button>
                        </el-input>
                    </div>
                </el-col> -->
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
        @sort-change="sortChange"
      >
        <!-- <el-table-column label="id" align="center"  prop="id" >
                        <template slot-scope="scope">
                            <span>{{ scope.row.id }}</span>
                        </template>
                    </el-table-column> -->
        <el-table-column label="激活码" align="center" prop="激活码">
          <template slot-scope="scope">
            <span>{{ scope.row.activationCode }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="激活码到期时间"
          align="center"
          prop="激活码到期时间"
        >
          <template slot-scope="scope" v-if="scope.row.endTime != null">
            <span>{{ scope.row.endTime | dataFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="使用状态" align="center" prop="使用状态">
          <template slot-scope="scope">
            <span>{{ scope.row.status == "0" ? "已使用" : "未使用" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建人" align="center" prop="创建人">
          <template slot-scope="scope">
            <span>{{ scope.row.createBy }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="创建时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | dataFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="更新人" align="center" prop="更新人">
          <template slot-scope="scope">
            <span>{{ scope.row.updataBy }}</span>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" align="center" prop="更新时间">
          <template slot-scope="scope">
            <span>{{ scope.row.updataTime | dataFormat }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.id, scope.row.userCode)"
              ></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >
              <i
                class="el-icon-delete textblue f16 ml20"
                @click="del(scope.row.id)"
              ></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
//导入文件下载插件
import fileDownload from "js-file-download";
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      value1: "",
      endDate: "",
      startDate: "",
      searchInput: "",
      searchInputTime: "",
      tableData: [],
      loading: false,
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 20,
        //查询条件
        condition: {
          id: "",
          activationCode: "",
        },
      },
      userlist: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addUserForm: {
        username: "",
        password: "",
        email: "",
        mobile: "",
      },
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
    };
  },

  created() {
    this.queryInfo.currPage = this.$route.query.currPage;
    this.queryInfo.pageSize = this.$route.query.pageSize;
    this.getListData();

    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //获取时间
    getDate() {
      console.log("时间", this.value1);
      this.startDate = this.value1[0];
      this.endDate = this.value1[1];
    },
    //搜索
    search() {
      this.getListData();
    },
    //批量导出
    exportCode() {
      this.loading = true;
      let setData = {};
      setData.startDate = this.startDate;
      setData.endDate = this.endDate;
      this.$http.post("activation/uploadExcel", setData).then((response) => {
        console.log("导出", response);
        if (response.data.code == 200) {
          for (let i = 0; i < response.data.data.length; i++) {
            let fileUrl = response.data.data[i];
           let elemIF = document.createElement("iframe");
            elemIF.src =fileUrl;
            elemIF.style.display = "none";
            document.body.appendChild(elemIF);

          }
          this.$notify.success({
            title: "提示",
            message: "导出成功！",
          });
          this.loading = false;
        }
      });
    },
    //条件查询
    search() {
      this.queryInfo.condition.activationCode = this.searchInput;
      this.getListData();
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange() {},
    //批量生成弹出框
    open() {
      this.$prompt("请输入数量", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // inputPlaceholder:''
      })
        .then(({ value }) => {
          var that = this;
          var data = {};
          data.num = value;
          var loginName2 = localStorage.getItem("loginName");
          var abc = JSON.parse(loginName2).value;
          data.createBy = abc;
          that.$http
            .post("/activation/addList", data)
            .then(function (response) {
              if (response.data.code == 200) {
                that.getListData();
                that.$notify.success({
                  title: "提示",
                  message: "生成成功",
                });
              }
            });
        })
        .catch(() => {});
    },
    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/activation/details",
        query: {
          id: id,
          currPage: that.queryInfo.currPage,
          pageSize: that.queryInfo.pageSize,
        },
      });
    },

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/activation/delete", { id: id })
          .then(function (response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.getListData();
          });
      }
    },
    getListData() {
      // 获取列表
      var that = this;
      that.queryInfo.condition.startDate = that.startDate;
      that.queryInfo.condition.endDate = that.endDate;
      that.$http
        .post("/activation/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    generate() {
      var that = this;
      var data = {};
      data.num = "123";
      console.log("---->data", data);
      that.$http.post("/activation/addList", data).then(function (response) {
        if (response.data.code == 200) {
          // console.log("生成成功！！")
        }
      });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    },
  },
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    .header-search-box .header-search-input {
      // width: 325px;
    }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
.toRight {
  // margin-left: 61.5%;
  float: right;
}
</style>

